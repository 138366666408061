import React, {
  useState,
  useCallback,
  useRef,
  // useEffect,
} from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import useShopify from '../../../services/shopify';
import Gallery from './Gallery';
import {
  ProductRecommendations,
  ProductTable,
  Guarantees,
} from '../../../components/eshop';
import { EshopBreadcrumb } from '../../../components/common';

import Description from './Description';
// import MoreInfos from './MoreInfos';
import ContentBlock from './ContentBlock';
import ReviewsBlock from './reviews';
import Faq from './FAQ';
import Deals from './Deals';

// import MIFIcon from '../../../images/eshop/madeinfr.png';
import { trackAddToCart } from '../../../utils/gtm';
import { getProductUrl } from '../../../utils/';
import { getLocaleSettings } from '../../../utils';

import trads from '../trads';

import {
  Wrapper,
  TwoColumnGrid,
  GridLeft,
  GridRight,
  StickyBlock,
  // Mif,
  Header,
  TableWrapper,
} from './Product.styles';
import { Title, Separation } from './common.styles';
import OilDescription from './OilDescription';
import classNames from 'classnames';
import { useLocale } from '../../../services/i18n/useLocale';

const cleanUrlParams = (search) => {
  const strip = `${decodeURI(search)}`.split('?').pop();
  if (!strip) {
    return strip;
  }

  return strip.split('&').reduce((acc, key) => {
    const parts = key.split('=');
    acc[parts[0]] = parts[1];
    return acc;
  }, {});
};

const getMatchedVariant = (orgVariants, params, viscosity) => {
  const hasFilter = Object.keys(params).length;

  const viscosities = orgVariants
    .filter((v) => (viscosity ? viscosity === v.viscosity : true));

  if (!viscosities?.length) {
    return orgVariants[0];
  }

  let available = viscosities.filter((v) => (hasFilter ? true : v.availableForSale));
  if (!available?.length) {
    available = viscosities;
  }

  const elected = available
    .find((v) =>
      v.selectedOptions.every((o) => {
        const optionAsked = params[o.name];
        if (!optionAsked) {
          return true;
        }
        return optionAsked && optionAsked === o.value;
      })
    );

  if (elected) {
    return elected;
  }

  return orgVariants[0];
};

const ProductPage = (props) => {
  const { product, review, deals = [] } = props;
  const orgVariants = product.variants;
  const intl = useLocale();
  const location = useLocation();
  const root = useRef();
  const urlParams = cleanUrlParams(location.search);
  const hasUrlParams = !!Object.keys(urlParams).length;
  const firstVariant = getMatchedVariant(
    orgVariants,
    urlParams,
    product.defaultVariant?.viscosity
  );
  const settings = getLocaleSettings(intl.locale);

  const { addToCart: apiAddToCart } = useShopify();

  const [state, setState] = useState({
    variants: orgVariants,
    variantId: firstVariant ? firstVariant.shopifyId : null,
  });

  // const productId = product?.shopifyId;
  const variantId = state.variantId;
  const variants = state.variants;
  const variant = variants.find((v) => v.shopifyId === variantId);
  // const variantSku = variant?.sku;

  const addToCart = useCallback(
    (variant, quantity) => {
      apiAddToCart(variant.shopifyId, quantity);
      trackAddToCart({
        name: product.title,
        category: product.productType,
        sku: variant.sku,
        variant: variant.title,
        price: variant.price,
        quantity,
      });
    },
    [apiAddToCart, product]
  );

  // Refetch product infos (stock and price)
  // useEffect(() => {
  //   //Fetch fresh data for price and stock
  //   const fetchData = async () => {
  //     const p = await apiFetchProduct(productId);

  //     const newVariants = orgVariants.map((v) => {
  //       const inf = p.variants.find(fv => fv.id === v.shopifyId);
  //       if (!inf) {
  //         return null;
  //       }

  //       return {
  //         ...v,
  //         availableForSale: !!inf.available,
  //         compareAtPrice: inf.compareAtPrice,
  //         price: inf.price,
  //       };
  //     }).filter(v => v);

  //     setState(prev => ({
  //       ...prev,
  //       variants: newVariants,
  //     }));
  //   };

  //   fetchData();
  // }, [apiFetchProduct, productId, orgVariants]);

  const selectOption = useCallback(
    (option, value) => {
      const othersOptions = variant.selectedOptions.filter(
        (o) => o.name !== option.name
      );

      // Find available variants
      const variantStack = variants
        .filter((v) => {
          return v.selectedOptions.find(
            (o) => o.name === option.name && o.value === value
          );
        })
        .map((v) => ({
          shopifyId: v.shopifyId,
          options: v.selectedOptions.filter((o) => o.name !== option.name),
          title: v.title,
        }));

      let newVariant = variantStack.find((v) => {
        return v.options.every((o) =>
          othersOptions.find((c) => c.name === o.name && c.value === o.value)
        );
      });

      if (!newVariant) {
        // If no match, pick the first
        newVariant = variantStack[0];
      }

      if (!newVariant) {
        return;
      }

      if (option.isViscosity) {
        navigate(
          getProductUrl(product.handle, intl.locale, {
            viscosity: value,
          }),
          {
            replace: true,
          }
        );
      } else {
        setState((prev) => ({
          ...prev,
          variantId: newVariant.shopifyId,
        }));
      }

      // setState(prev => ({
      //   ...prev,
      //   variantId: newVariant.shopifyId
      // }));
    },
    [variants, variant, product, intl.locale]
  );

  // console.log('[Product] Render', variant);

  // filter 2nd liste based on selected variante
  const options = product.options.map((group, idx) => {
    if (idx === 0) {
      return group;
    }

    const values = variants
      .map((v) => v.selectedOptions)
      .filter((v) => v[0].value === variant.selectedOptions[0].value)
      .map((v) => v[idx].value);

    return {
      ...group,
      values,
    };
  });

  const showReviews = settings.shop && review && review.starsAverage >= 4;
  const isOilTemplate =
    product.rootCategory?.customfields?.isOilTemplate || false;

  return (
    <Wrapper ref={root}>
      <div className="container top-product-infos">
        <Header>
          <EshopBreadcrumb category={product.rootCategory} subCategory={product.secondaryCategory} product={product} />
          {/* <Mif>
            <div className="item">
              <img
                src={MIFIcon}
                height={17}
                width={17}
                alt="Made in france"
              />
              {intl.formatMessage(trads.madeInFrance)}
            </div>
          </Mif> */}
        </Header>
        <TwoColumnGrid>
          <GridRight>
            <Gallery
              product={product}
              variant={variant}
              mobileOnly
              variantOnly
              sliderMode
              forceInitialRedraw={hasUrlParams}
            />
            <StickyBlock
              className={classNames({
                activate: !isOilTemplate,
              })}
            >
              <Description
                product={product}
                options={options}
                variant={variant}
                selectOption={selectOption}
                addToCart={addToCart}
                shopOptions={props.shopOptions}
                slim={isOilTemplate}
                rate={review?.starsAverage}
                rootNode={root}
              />
            </StickyBlock>
          </GridRight>
          <GridLeft>
            <Gallery
              product={product}
              variant={variant}
              commonOnly
              mobileOnly
            />

            <Gallery
              product={product}
              variant={variant}
              hideMobile
              sliderMode={isOilTemplate}
              hideSliderDots={isOilTemplate}
            />
          </GridLeft>
        </TwoColumnGrid>
      </div>
      {isOilTemplate && <OilDescription product={product} />}
      <div className="whitebg">
        {!!deals?.length && <Deals deals={deals} currentProduct={product} />}
        {!!product.contentBlocks?.length && (
          <div className="container">
            {product.contentBlocks.map((block, idx) => (
              <React.Fragment key={idx}>
                {/* {idx !== 0 && <Separation />} */}
                <ContentBlock key={block.id} {...block} />
              </React.Fragment>
            ))}
          </div>
        )}
        {showReviews && (
          <ReviewsBlock
            product={product}
            variant={variant}
            review={review}
            key={variant.id}
          />
        )}
        <div className="container">
          {!!props.table && (
            <>
              <TableWrapper>
                <Title>{intl.formatMessage(trads.productTableTitle)}</Title>
                <ProductTable
                  labels={props.table.labels}
                  columns={props.table.columns}
                  currentProductText={intl.formatMessage(
                    trads.productTableCurrent
                  )}
                />
              </TableWrapper>
            </>
          )}
          <Faq
            sections={[
              {
                noFaq: true,
                title: intl.formatMessage(trads.productWarnings),
                content: `${product.moreInfos || ''} ${product.legal || ''}`.trim(),
              },
              ...product.faq || [],
            ].filter(section => section.content)}
          />
          <Separation className="desktop" />
          {/* {!!(product.moreInfos || product.legal) && (
            <>
              <MoreInfos
                description={product.moreInfos}
                legal={product.legal}
              />
              <Separation className="desktop" />
            </>
          )} */}
          <ProductRecommendations
            recommendations={product.recommendations}
            showTitle
            className="recos"
          />
        </div>
        <Guarantees />
      </div>
    </Wrapper>
  );
};

export default ProductPage;
