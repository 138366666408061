import React from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { useIntersection } from 'react-use';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import { Price } from '../../../components/eshop';
import trads from '../trads';

import { Title } from './common.styles';
import usePortal from '../../../utils/usePortal';
import { Wrapper } from './AddToCartFixed.styles';
import { useLocale } from '../../../services/i18n/useLocale';

const AddToCartFixedPortal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

const AddToCartFixed = ({ product, variant, wrapperRef, cartRef, rootRef }) => {
  const intl = useLocale();
  const headerHeight = 70;

  const intersection = useIntersection(cartRef, {
    root: null,
    rootMargin: `-${headerHeight}px 0px 0px 0px`,
    threshold: 0,
  });

  const rootIntersection = useIntersection(rootRef, {
    root: null,
    rootMargin: `-100% 0px 0px 0px`,
    threshold: 0,
  });

  const showReminder =
    intersection &&
    rootIntersection?.isIntersecting &&
    !intersection?.isIntersecting &&
    intersection?.boundingClientRect?.bottom <= headerHeight;

  const onScrollTo = React.useCallback(() => {
    if (!wrapperRef) {
      return;
    }
    elementScrollIntoView(wrapperRef.current, {
      duration: 700,
      block: 'center',
      inline: 'center',
      behavior: 'smooth',
    });
  }, [wrapperRef]);

  return (
    <AddToCartFixedPortal id="fixed-cart">
      <Wrapper
        className={classNames({
          show: showReminder,
        })}
        onClick={onScrollTo}
      >
        <div className="container">
          <div className="product-title">
            <Title>
              <div>{product.title}</div>
            </Title>
          </div>
          <Price
            price={variant.price}
            originalPrice={variant.compareAtPrice}
            alignLeft
          />
          <div className="simple-link block buy">
            {intl.formatMessage(trads.buy)}
          </div>
        </div>
      </Wrapper>
    </AddToCartFixedPortal>
  );
};

export default AddToCartFixed;
