import React from 'react';
// import Image from 'gatsby-image';
// import cn from 'classnames';

// import HtmlRender from '../../../components/common/Html';

import { Container, TitleBloc, Products, DealBox, Cta } from './Deals.styles';

import { Title, Wrapper } from './common.styles';
import { Price } from '../../../components/eshop';
import useShopify from '../../../services/shopify';
import { trackAddToCart } from '../../../utils/gtm';
import { useCallback } from 'react';
import Image from '../../../utils/Imgix';
import { CloudinaryFluid, nl2br } from '../../../utils';
import classNames from 'classnames';
import trads from '../trads';
import { useLocale } from '../../../services/i18n/useLocale';
// import classNames from 'classnames';

const DealCard = ({ deal, currentProduct }) => {
  const { addToCart: apiAddToCart } = useShopify();
  const intl = useLocale();

  const currentProductPrice = parseFloat(
    currentProduct.defaultVariant.price,
    10
  );
  const secondaryProductPrice = parseFloat(
    deal.product.defaultVariant.price,
    10
  );

  const originalPrice = currentProductPrice + secondaryProductPrice;
  const discount =
    Math.round(secondaryProductPrice * parseFloat(deal.discount, 10)) / 100;

  const packPrice = originalPrice - discount;

  const addToCart = useCallback(async () => {
    const variants = [deal.product, currentProduct];

    const quantity = 1;
    await apiAddToCart(
      variants.map((v) => v.defaultVariant.shopifyId),
      quantity
    );
    variants.forEach((product) => {
      trackAddToCart({
        name: product.title,
        category: product.productType,
        sku: product.defaultVariant.sku,
        variant: product.defaultVariant.title,
        price:
          Math.round(
            (parseFloat(product.defaultVariant.price, 10) -
              discount / variants.length) *
            100
          ) / 100,
        quantity,
      });
    });
  }, [apiAddToCart, deal, currentProduct, discount]);

  const image = Array.isArray(deal.image) && deal.image.length ? CloudinaryFluid(deal.image[0], { maxWidth: 330 }) : false;

  return (
    <DealBox className="deal">
      <div className="title-container">
        <div className="text">
          <Title as="h3" className="deal-name">
            {deal.name}
          </Title>
          <div className="product-name">1 {currentProduct.title}</div>
          <div className="product-name">1 {deal.product.title}</div>
        </div>
        <Price originalPrice={originalPrice} price={packPrice} stacked />
      </div>
      {!!image && (
        <Image
          fluid={image}
          alt={deal.name}
          className="packshot"
        />
      )}
      <Cta>
        <div className="discount-account">
          {intl.formatMessage(trads.dealsSave)} <Price price={discount} />
        </div>
        <button className="simple-link block buy" onClick={addToCart}>
          {intl.formatMessage(trads.dealsCTA)}
        </button>
      </Cta>
    </DealBox>
  );
};

const Deals = ({ deals, currentProduct }) => {
  const intl = useLocale();

  return (
    <Wrapper>
      <Container className={classNames("container", {
        solo: deals.length <= 1
      })}>
        <Products>
          {deals.map((deal, idx) => (
            <DealCard key={idx} deal={deal} currentProduct={currentProduct} />
          ))}
        </Products>
        <TitleBloc>
          <Title>
            {nl2br(intl.formatMessage(trads.dealsTitle))}
            {/* Bons&nbsp;
            <br />
            plans */}
          </Title>
        </TitleBloc>
      </Container>
    </Wrapper>
  );
};

export default Deals;
