import React from 'react';

import Stamped from './Stamped';

import trads from '../../trads';
import { getLocaleSettings } from '../../../../utils';

import { Wrapper } from './index.styles';

import { Title } from '../common.styles';
import { useLocale } from '../../../../services/i18n/useLocale';

const Feedbacks = ({ product, review }) => {
  const intl = useLocale();
  const settings = getLocaleSettings(intl.locale);

  if (!settings.shop) {
    return null;
  }

  return (
    <Wrapper id="reviews">
      <div className="container">
        <Title>{intl.formatMessage(trads.feedbackTitle)}</Title>
        <Stamped
          productId={review.stampedId}
          key={product.shopifyHumanId}
          locale={intl.locale}
        />
      </div>
    </Wrapper>
  );
};

export default Feedbacks;
