import React from 'react';
import ArrowIconRight from '../../../../icons/arrow-black-right.svg';
import ArrowIconLeft from '../../../../icons/arrow-black-left.svg';
import { Icon } from '../../../../components/icons';
import { Wrapper } from './Pagination.styles';

const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (v, k) => k + start);
};

const Pagination = ({ maxPage = 1, onClickAction, page }) => {
  const getPages = () => {
    if (maxPage > 0 && maxPage <= 5) {
      return range(1, maxPage);
    }
    if (page - 3 <= 0) {
      return range(1, 5);
    }
    const tmpPages = [];
    const iterater = [-2, -1, 0, 1, 2];
    iterater.forEach((i) => {
      const tmpPage = page + i;
      if (tmpPage > maxPage) {
        return true;
      }
      tmpPages.push(tmpPage);
    });

    return tmpPages;
  };

  const pages = getPages();

  if (maxPage <= 1) {
    return null;
  }

  return (
    <Wrapper>
      <ul className="paginate">
        {page > 1 && (
          <li onClick={() => onClickAction && onClickAction(page - 1)}>
            <Icon src={ArrowIconLeft} height={24} width={24} />
          </li>
        )}
        {pages.map((i) => {
          return (
            <li
              key={i}
              className={page === i ? 'current' : ''}
              onClick={() => onClickAction && onClickAction(i)}
            >
              {i}
            </li>
          );
        })}
        {page < maxPage && (
          <li onClick={() => onClickAction && onClickAction(page + 1)}>
            <Icon src={ArrowIconRight} height={24} width={24} />
          </li>
        )}
      </ul>
    </Wrapper>
  );
};

export default Pagination;
