import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;

  li {
    font-weight: normal;
    list-style: none;
  }

  .paginate {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: inline-flex;
    display: -ms-inline-flexbox;
    padding: 9px 8px;
  }

  .paginate li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
  }

  .paginate li:hover {
    background: #d7d7d7;
    color: #4f664e;
  }

  .paginate li.current {
    border: 1px solid black;
  }
`;