import React from 'react';
import RatingStars from '../RatingStars';
import CheckMarkIcon from '../../../../icons/GreenCheckMark.svg';
import { Icon } from '../../../../components/icons';

import { Wrapper } from './Review.styles';

const Review = ({ title, rating, comment, username, date, verifiedText }) => {
  return (
    <Wrapper>
      <RatingStars rate={rating} />
      <div className="avis-date-wrapper">
        <div className="avis">{title}</div>
        <div className="date">{date}</div>
      </div>
      <div className="comment">{`"${comment}"`}</div>
      <div className="username">{username}</div>
      <div className="certified">
        <Icon src={CheckMarkIcon} height={10} width={12} />
        <div>{verifiedText}</div>
      </div>
    </Wrapper>
  );
};

export default Review;
