import styled from 'styled-components';

import { Wrapper as WrappeRaw } from '../common.styles';

export const Wrapper = styled(WrappeRaw)`
  display: flex;
  flex-direction: column;
  background: #eef1f6;
  padding-top: 45px;
  padding-bottom: 45px;

  .container {
    width: 100%;
  }
`;
