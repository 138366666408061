import styled from 'styled-components';
// import Image from 'gatsby-image';

import {
  device,
  Imgix
} from '../../../utils';

export const Wrapper = styled.div`
  margin: 20px 0 40px;
  position: relative;

  @media ${device.laptop} {
    margin-bottom: 0;
    margin-top: 0;
  }

  &.mobile-mode {
    margin-top: 0;
    @media ${device.laptop} {
      display: none;
    }
  }

  &.desktop-mode {
    @media ${device.laptopDown} {
      display: none;
    }
  }

  &.slider-mode {
    @media ${device.tabletDown} {
      margin-left: -20px;
      margin-right: -20px;
    }

    .slider-list {
      padding-bottom: 100%!important;
    }

    .slider-control-bottomcenter {
      bottom:-20px!important;
    }
    .slider-control-bottomcenter {
      .paging-item {
        button {
          padding: 2px 8px;
          opacity: 0.3!important;

          svg {
            transform: scale(1.4);
            transform-origin: center;
          }
        }

        &.active {
          button {
            opacity: 0.8!important;
          }
        }
      }
    }
  }

  .product-image {
    width: 100%;
    &.common {
      margin: 50px 0;
    }
  }
  legend {
    font-size: 12px;
    font-weight: 400;
    color: #74767a;
    margin-bottom: 20px;
    line-height: 1.4em;

    @media ${device.tablet} {
      font-size: 14px;
      margin-bottom: 40px;
      font-weight: 300;
    }

    @media ${device.laptop} {
      font-size: 16px;
    }
  }

  .slide-container {
    height: 100%;

    &:last-child {
      .product-image {
        margin-bottom: 0;
      }
    }
  }
`;

export const ThumbnailsSticky = styled.div`
  position: fixed;
  top: 150px;
  left: 20px;
  z-index: 101;

  @media (min-width: 1440px) {
    left: 50%;
    margin-left: ${`${(-1440 / 2) + 20}px`};
  }

  &.fixed {
    position: absolute;
    top: 30px;
    left: 0;
    margin-left: 0;
  }
`;

export const ImagesWrapper = styled.div`
  max-width: 100vw;

  @media ${device.tablet} and ${device.laptopDown} {
    max-width: 500px;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    margin-left: 40px;
  }
`;

export const Thumbnails = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    width: 80px;
    height: 80px;
    box-shadow: 2px 7px 15px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #d7d7d7;
    background-color: #fafafa;
    margin: 15px 0;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    .product-image {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    display: block;
  }
`;

export const Img = styled(Imgix)
  `
  max-width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 1.45rem;

  &.in-slider {
    margin-bottom: 0;
    height: 100%;
  }
`;