import styled from 'styled-components';


import {
  device
} from '../../../utils';

export const Wrapper = styled.main `
  background: #EEF1F6;
  margin-top: 30px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  .container {
    max-width: 800px;
    padding-top: 45px;
    padding-bottom: 45px;

    @media ${device.tablet} {
      text-align: center;
    }
  }
`;


export const Informations = styled.div `
  .description {
    /* font-weight: 300; */
    font-size: 14px;
    line-height: 1.6em;
    margin: 15px 0;
    color: #575757;
  }

  .description-title {
    margin-bottom: 15px;
    justify-content: flex-start;
    text-align: left;
    max-width: 60%;

    @media ${device.tablet} {
      justify-content: center;
      max-width: none;
    }

  }

  p {
    line-height: 1.6em;
    color: #000;
  }

  &, a {
    color: #111111;
    font-size: 14px;
  }

  a {
    text-decoration: underline;
  }
`;


export const Features = styled.div `
  display: grid;
  margin-top: 20px;
  grid-gap: 15px;
  flex-wrap: wrap;

  .feature {
    display: flex;
    font-family: 'Montserrat';
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3em;
    text-align: left;

    span {
      margin-left: 15px;
    }
  }

  @media ${device.tablet} {
    margin: 20px 30px 0 30px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px 40px;

    .feature {

      span {
        margin-left: 15px;
      }
    }
  }

  .icon-container {
    background: #38B84C;
    border-radius: 50%;
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    flex: 0 0 20px;
    justify-content: center;
    align-items: center;

    svg {
      filter: brightness(0) invert(1);
    }
  }
`;