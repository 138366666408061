import React from 'react';

import AddToCart from './AddToCart';
import Features from '../../../components/eshop/Features';
import ProductStatus from '../../../components/eshop/ShippingStatus';
import Rating from './Rating';

import {
  Wrapper,
  Title,
  Category,
  Informations,
  DisplayOrder,
  AddToCartWrapper,
} from './Description.styles';

const Description = ({
  product,
  options,
  addToCart,
  variant,
  selectOption,
  shopOptions,
  rate,
  slim,
  rootNode,
}) => {
  return (
    <Wrapper>
      <h1 className="nostyle">
        <Title className="bigtitle">{product.title}</Title>
        <Category>{product.subtitle || product.productType}</Category>
      </h1>

      {rate >= 4 && (
        <Rating rate={rate} />
      )}

      <DisplayOrder>
        <AddToCartWrapper>
          <AddToCart
            options={options}
            variant={variant}
            addToCart={addToCart}
            selectOption={selectOption}
            product={product}
            rootNode={rootNode}
          />
          <ProductStatus
            variant={variant}
            shopOptions={shopOptions}
            className="status"
            isProductPage
          />
        </AddToCartWrapper>
        {!slim && (
          <Informations>
            <div className="description"
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
            <Features product={product} />
          </Informations>
        )}
      </DisplayOrder>
    </Wrapper>
  );
};

export default Description;