import React from 'react';
import { graphql } from 'gatsby';

import Seo, { ProductSnippet } from '../../components/seo';
import ProductScreen from '../../screens/eshop/product';

import { oneMatch, Product, regroup } from '../../utils';
import { trackProductDetail } from '../../utils/gtm';
import { useLocale } from '../../services/i18n/useLocale';
import { JASOReformatViscosity } from '../../utils/jaso';

const processTable = (table, products = [], currentProductId) => {
  if (!table || !products.length) {
    return undefined;
  }

  const result = {
    labels: table.lines,
    columns: table.body
      .map((col) => {
        const variants = col.primary.product?.variants.map((v) => v.sku);
        const product = products.find((p) => {
          return oneMatch(p.skus, variants);
        });

        return {
          product,
          rows: col.items.map((i) => i.content),
          current: product?.shopifyId === currentProductId,
        };
      })
      .filter((item) => item.product),
  };

  // Sort columns based on current product
  // const moveFrom = result.columns.findIndex(col => col.product.shopifyId === currentProductId);
  // if (moveFrom >= 1) {
  //   const [element] = result.columns.splice(moveFrom, 1);
  //   result.columns.splice(0, 0, element);
  // }
  return result;
};

const ProductTemplate = ({ data, pageContext }) => {
  const intl = useLocale();
  // console.log('before', pageContext);
  const { viscosity } = pageContext;

  const product = Product(data.shopifyProduct, intl.locale);
  const ogImage = product.thumbnail?.originalSrc;
  const table = processTable(
    data.table?.data,
    data.fetchProducts.nodes,
    product.shopifyId
  );

  if (viscosity) {
    product.defaultVariant = product.variants.find(
      (v) => v.viscosity === viscosity
    );
  }

  trackProductDetail({
    name: product.title,
    variants: [
      {
        sku: product.defaultVariant?.sku,
        name: product.title,
        price: product.defaultVariant?.price,
        category: product.productType,
        variant: product.defaultVariant?.title,
      },
    ],
    others: product.recommendations.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      category: p.productType,
      variant: p.defaultVariant?.title,
      list: 'Similar Products',
    })),
  });

  const shopOptions = regroup(data.menu.nodes.slice().shift()?.data || {});
  const viscosityCount = product.options.reduce((cnt, i) => {
    if (!i.isViscosity) {
      return cnt;
    }

    return cnt + i.values.length;
  }, 0);

  if (viscosityCount > 1) {
    product.title = JASOReformatViscosity(`${product.title} ${viscosity}`);
  }

  const seoTitle = [product.productType, product.title]
    .filter((s) => s)
    .join(' ');
  // console.log('after', product);

  const deals = (data.shopifyProduct.customfields.deal || [])
    .map((deal) => ({
      ...deal,
      discount: parseInt(deal.discount, 10) || 0,
      product: data.fetchProducts.nodes.find(
        (product) => product.skus.indexOf(deal.product) !== -1
      ),
      productSku: deal.product,
    }))
    .filter((d) => {
      return d.product && d.productSku && d.image && d.name && d.discount;
    });

  return (
    <>
      <Seo
        title={seoTitle.trim()}
        description={product.description}
        image={ogImage}
        preconnect={['https://cdn.shopify.com', 'https://cld.accentuate.io']}
      />

      <ProductSnippet product={product} review={data.reviews} />
      <ProductScreen
        product={product}
        shopOptions={shopOptions?.cart}
        table={table}
        review={data.reviews}
        deals={deals}
      />
    </>
  );
};

export const query = graphql`
  query EshopProduct(
    $shopifyId: String!
    $productsTableId: String
    $fetchProducts: [String!]
    $locale: String!
    $cmsLocaleGlob: String!
  ) {
    menu: allPrismicMenu(filter: { lang: { glob: $cmsLocaleGlob } }) {
      nodes {
        data {
          cart_banner
          cart_stock_label
          cart_ship_details
          cart_ship_limit_hour
          cart_holidays_start
          cart_holidays_message
          cart_holidays_end
        }
      }
    }
    shopifyProduct(shopifyId: { eq: $shopifyId }, locale: { eq: $locale }) {
      id
      shopifyHumanId
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      thumbnail {
        originalSrc
      }
      options {
        id
        name
        values
        isViscosity
      }
      collections {
        title
        handle
        customfields {
          subCategories
          isRootCategory
          isOilTemplate
          # isProductRange
        }
      }
      customfields {
        # moreImages {
        #   image {
        #     alt
        #     aspect_ratio
        #     cloudinary_src
        #   }
        #   text
        # }
        discontinued
        subtitle
        descriptionTitle
        faq {
          content
          title
        }
        deal {
          name
          discount
          product
          image {
            cloudinary_src
            aspect_ratio
            alt
            height
            width
          }
        }
        contentBlock {
          text
          textLeft
          subhead
          title
          video
          video_mobile
          image {
            cloudinary_src
            aspect_ratio
            alt
            height
            width
          }
        }
        moreInfos
        legal
        features {
          icon {
            cloudinary_src
            aspect_ratio
            alt
          }
          text
        }
      }
      defaultVariant {
        shopifyId
        title
        barcode
        sku
        price
        compareAtPrice
        viscosity
      }
      variants {
        id
        sku
        barcode
        title
        price
        compareAtPrice
        availableForSale
        shopifyId
        viscosity
        selectedOptions {
          name
          value
        }
        image {
          id
          originalSrc
          altText
          # localFile: localThumb {
          #   childImageSharp {
          #     fluid(maxWidth: 5, srcSetBreakpoints: [5]) {
          #       aspectRatio
          #       tracedSVG
          #     }
          #   }
          # }
        }
        customfields {
          moreImages {
            image {
              alt
              aspect_ratio
              cloudinary_src
              id
            }
            text
          }
        }
      }
      recommendations {
        ...ProductItem
      }
    }
    reviews: productReview(
      product: { locale: { eq: $locale }, shopifyId: { eq: $shopifyId } }
    ) {
      id
      stampedId
      starsAverage
      total
    }
    table: prismicProductTables(id: { eq: $productsTableId }) {
      data {
        body {
          ... on PrismicProductTablesDataBodyProduit {
            id
            items {
              content {
                html
              }
            }
            primary {
              product {
                variants {
                  sku
                }
              }
            }
          }
        }
        lines {
          label
        }
      }
    }
    fetchProducts: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
      }
    }
  }
`;
export default ProductTemplate;
