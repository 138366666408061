import React from 'react';
import trads from '../trads';
import { Icon } from '../../../components/icons';
import GreenCheckMark from '../../../icons/GreenCheckMark.svg';
import { Title } from './common.styles';

import { Wrapper, Features, Informations } from './OilDescription.styles';
import { useLocale } from '../../../services/i18n/useLocale';

const OilDescription = ({ product }) => {
  const intl = useLocale();

  return (
    <Wrapper>
      <div className="container">
        <Informations>
          <Title className="description-title">
            {product.descriptionTitle
              ? product.descriptionTitle
              : intl.formatMessage(trads.productDescriptionTitle)}
          </Title>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          />
        </Informations>
        {!!product.features?.length && (
          <Features>
            {product.features.map((feature) => (
              <div key={feature.text} className="feature">
                <div className="icon-container">
                  <Icon src={GreenCheckMark} height={10} width={10} />
                </div>
                <span>{feature.text}</span>
              </div>
            ))}
          </Features>
        )}
      </div>
    </Wrapper>
  );
};

export default OilDescription;
