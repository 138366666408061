import styled from 'styled-components';
import { device } from '../../../../utils';

export const Wrapper = styled.section``;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .totalRating {
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
  }

  .stars-avis {
    display: flex;
    align-items: end;
    column-gap: 20px;
    flex-wrap: wrap;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px 50px;
  margin: 30px 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
