import styled from 'styled-components';


import {
  device
} from '../../../utils';

export const Wrapper = styled.main`
  box-shadow: 0px -5px 10px 0 rgba(182, 182, 182, 0.3);
  background-color: #ffffff;
  padding: 30px 20px;
  padding-bottom: 0;
  margin: 0 -20px;
  backface-visibility: hidden;

  @media ${device.tablet} {
    box-shadow: 5px -5px 20px 0 rgba(182, 182, 182, 0.35);
    margin: 0;
    padding-bottom: 15px;
  }

  @media ${device.laptop} {
    box-shadow: 5px 16px 25px 0 rgba(182, 182, 182, 0.38);
    border-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 30px 50px 30px 60px;
  }

  .product-range {
    text-decoration: none;
  }

  h1.nostyle {
    margin: 0;
    padding: 0;
  }
`;

export const Title = styled.span`
  font-size: 20px;
  word-wrap: break-word;
  margin: 0;
  line-height: 1em;
  display: block;
  text-align: center;


  @media ${device.laptop} {
    font-size: 30px;
    text-align: left;
  }
`;

export const Category = styled.span`
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  margin: 10px 0;
  text-transform: none;
  display: block;
  text-align: center;
  font-family: 'Roboto';

  @media ${device.laptop} {
    text-align: left;
    margin: 5px 0;
  }
`;

export const Informations = styled.div`
  .description {
    /* font-weight: 300; */
    font-size: 14px;
    margin: 15px 0;
    color: #575757;
  }

  p {
    line-height: 1.5em;
  }

  &, a {
    color: #111111;
    font-size: 14px;
  }

  a {
    text-decoration: underline;
  }
`;

export const AddToCartWrapper = styled.div`
  .status {
    margin: 40px 0 40px 0;
  }
`;

export const DisplayOrder = styled.div`
  display: flex;
  flex-direction: column;

  ${AddToCartWrapper} {
    margin-top: 20px;
  }

  ${Informations} {
    margin-top: 20px;
  }

  @media ${device.laptop} {
    flex-direction: column-reverse;

    ${Informations} {
      margin-top: 0px;
    }

    ${AddToCartWrapper} {
      margin-top: 30px;
    }
  }
`;