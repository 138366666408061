import styled from 'styled-components';

import {
  device
} from '../../../utils';

export const Wrapper = styled.div `
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  
  transform: translateY(100%);
  opacity: 0;
  border-top: 1px solid #E5E5E5;
  backdrop-filter: saturate(110%) blur(2px);
  background-color: rgba(242, 243, 245, 0.9);

  font-size: 15px;
  font-weight: 500;
  font-style: normal;

  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  &.show {
    transform: translateY(0);
    opacity: 1;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
  }

  .simple-link {
    flex: 0;
    color: #FFF;
    cursor: pointer;
  }
  .product-title {
    flex-grow: 1;
    overflow: hidden;
    margin: 0;
    margin-right: 15px;

    & > * {
      display: flex;
      font-size: 14px;
      min-width: 0;
      align-items: center;
      justify-content: flex-start;
      margin: 0;

      & > * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .price {
    flex: 0;
    margin: 0;
    padding: 0;
    margin-right: 15px;
  }

  .price, .product-title, .simple-link {
    white-space: nowrap;
  }
`;