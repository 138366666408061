import styled from 'styled-components';

import {
  device
} from '../../../utils';
import {
  Wrapper as WrapperRaw
} from './common.styles';

export const Wrapper = styled.section`
  width: 100%;
  background: #FFF;

  .top-product-infos {
    background-color: #FAFAFA;
  }

  @media ${device.tablet} {
    background-color: #FAFAFA;

    .top-product-infos {
      background: none;
    }
  }

  .whitebg {
    width: 100%;
    background-color: #FFF;
    padding-top: .1px;
    padding-bottom: .1px; /* include bottom margin */
  }

  .recos {
    margin-bottom: 70px;
  }

  @media ${device.tablet} {
    .recos {
      margin-bottom: 50px;
    }
  }
`;


export const TableWrapper = styled(WrapperRaw)`
`;


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const TwoColumnGrid = styled.div`
  display: grid;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 3rem 1fr;
    grid-template-areas: "left . right";
    margin-bottom: 30px;
  }

  @media ${device.laptopM} {
    display: grid;
    grid-template-columns: 1fr 7rem 1fr;
    grid-template-areas: "left . right";
    margin-bottom: 30px;
  }
`;

export const GridLeft = styled.div`
  @media ${device.tablet} {
    margin: 0;
  }

  @media ${device.laptop} {
    grid-area: left;
  }
`;

export const GridRight = styled.div`
  position: relative;

  @media ${device.laptop} {
    grid-area: right;
    min-width: 550px;
    margin-right: -20px;
  }
`;

export const StickyBlock = styled.div`
  @media ${device.tablet} {
    padding-top: 30px;
  }

  @media ${device.laptop} {
    margin-bottom: 20px;

    &.activate {
      position: sticky;
      top: var(--header-height);
    }
  }
`;

export const Mif = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 14px;
    padding-right: 20px;
    margin-top: 20px;
  }
/* 
  @media ${device.tabletDown} {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-right: 0;
    margin-left: auto;

    font-size: 12px;

    img, .icon {
      height: 15px!important;
    }
  } */

  img, .icon {
    margin-right: 5px;
  }

  img {
    margin-left: 20px;
  }

  .item {
    display: flex;
    align-items: center;
  }
`;