import styled from 'styled-components';

import { device } from '../../../utils';



export const Products = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  flex: 2;
  
  @media ${device.tablet} {
    flex-direction: row;
    background: #f2f3f5;
    padding: 20px;
  }
`;

export const DealBox = styled.div`
  flex: 1;
  background: #f2f3f5;
  padding: 20px;

  .packshot {
    margin: 10px 0;
  }

  .title-container {
    display: flex;
  }

  .deal-name {
    margin-bottom: 5px;
  }

  .product-name {
    text-transform: uppercase;
    font-size: 14px;
  }

  .text {
    flex: 1 1 auto;
  }

  .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    margin-top: 0;
    padding-top: 0;
    text-align: right;
    margin-left: 20px;

    .dashed {
      color: #000;
      font-weight: bold;
      font-family: 'Montserrat';
      margin-right: 0;
    }

    .price-value {
      color: #c80f05;
    }
  }

  .buy {
    color: #fff;
    border: 0px;
    margin: 15px auto 0 auto;
  }

  @media ${device.tablet} {
    background: #fff;
  }
`;

export const TitleBloc = styled.div`
  background: #00b0c8;
  flex: 1.3;
  padding: 25px;
  margin-bottom: 20px;

  h2 {
    font-weight: 900;
    font-size: 30px;
    color: #fff;
    line-height: 1em;
    margin: 0;
  }

  @media ${device.laptopDown} {
    h2 {
      br {
        display: none;
      }
    }
  }

  @media ${device.laptop} {
    padding: 35px;
    margin-left: 20px;
    margin-bottom: 0;

    h2 {
      font-size: 45px;
    }
  }
`;

export const Cta = styled.div`
  text-align: center;

  .discount-account {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    .price {
      margin-left: 10px;
    }

    &,
    font-size {
      font-size: 17px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media ${device.laptop} {
    flex-direction: row;
  }

  &.solo {
    ${TitleBloc} {
      flex: 4;
    }
  }
`;