import React, { useCallback, useRef, useState } from 'react';
import { windowScrollTo } from 'seamless-scroll-polyfill';
import csx from 'classnames';
import { useWindowScroll } from 'react-use';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import Carousel from '../../../components/common/Slider';
import { useBreakpoint } from '../../../utils';

import {
  Wrapper,
  ImagesWrapper,
  ThumbnailsSticky,
  Thumbnails,
  Img,
} from './Gallery.styles';
import classNames from 'classnames';

const ImagesContainer = ({ isSlider, activeIndex, children, hideDots }) => {
  if (!isSlider || children.length <= 1) {
    return <ImagesWrapper>{children}</ImagesWrapper>;
  }

  return (
    <ImagesWrapper>
      <Carousel activeIndex={activeIndex} hideDots={hideDots}>
        {children}
      </Carousel>
    </ImagesWrapper>
  );
};

const StickThumbs = ({
  container,
  images,
  defaultAlt,
  onClick,
  fixed = false,
}) => {
  const ref = useRef();
  const { y } = useWindowScroll();
  const elemTop = container?.current?.offsetTop || -1;
  const elemHeight = container?.current?.offsetHeight || -1;
  const boxHeight = ref?.current?.offsetHeight || -1;
  const boxTop = 150;

  let top = boxTop;

  if (elemHeight > -1 && y + boxHeight + boxTop > elemTop + elemHeight) {
    top = elemHeight + elemTop - y - boxHeight;
  }

  return (
    <ThumbnailsSticky
      ref={ref}
      style={{
        top: !fixed ? top : undefined,
      }}
      className={classNames({
        fixed,
      })}
    >
      <Thumbnails>
        {images.map((image, idx) => (
          <li
            key={image.id}
            onClick={() => {
              if (!onClick) {
                return;
              }

              onClick(image.id, idx);
            }}
            role="presentation"
          >
            <Img
              fluid={image.thumbnail}
              alt={image.altText || defaultAlt}
              className="product-image"
              loading="lazy"
            />
          </li>
        ))}
      </Thumbnails>
    </ThumbnailsSticky>
  );
};

const Gallery = ({
  product,
  variant,
  variantOnly,
  commonOnly,
  mobileOnly,
  sliderMode,
  hideMobile,
  hideSliderDots,
  forceInitialRedraw,
  // teesterVideoId
}) => {
  const device = useBreakpoint();
  const ref = React.useRef(null);
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0);

  const images = [
    ...(commonOnly ? [] : variant.images),
    ...(variantOnly
      ? []
      : product.commonImages.map((i) => ({
          ...i,
          isCommon: true,
        }))),
  ].filter((i) => i.fluid);

  const onThumbClick = useCallback(
    (imageId, idx) => {
      if (sliderMode) {
        setSliderActiveIndex(idx);
        return;
      }

      const target = document.getElementById(`productImage-${imageId}-desktop`);

      if (!target) {
        return;
      }

      windowScrollTo({
        duration: 700,
        behavior: 'smooth',
        top: target.offsetTop,
      });
    },
    [sliderMode]
  );

  if (!images.length) {
    return null;
  }

  return (
    <Wrapper
      ref={ref}
      className={csx({
        'mobile-mode': mobileOnly,
        'desktop-mode': hideMobile,
        'slider-mode': sliderMode,
      })}
    >
      {hideMobile &&
      device !== 'ssr' &&
      device !== 'mobile' &&
      device !== 'tablet' ? (
        <StickThumbs
          images={images}
          defaultAlt={product.title}
          container={ref}
          onClick={onThumbClick}
          fixed={sliderMode}
        />
      ) : null}

      <ImagesContainer
        isSlider={sliderMode}
        activeIndex={sliderActiveIndex}
        hideDots={hideSliderDots}
      >
        {images.map((image, idx) => (
          <div
            key={image.id}
            id={`productImage-${image.id}-${hideMobile ? 'desktop' : 'mobile'}`}
            className="slide-container"
          >
            <Zoom
              wrapStyle={{
                width: '100%',
              }}
            >
              <Img
                key={!forceInitialRedraw ? image.id : `${image.id}-${device}`}
                fluid={image.fluid}
                alt={image.altText || product.title}
                className={csx('product-image', {
                  common: image.isCommon,
                  'in-slider': sliderMode,
                })}
                // loading={idx === 0 ? 'eager' : 'lazy'}
                preloadHead={idx === 0}
                fadeIn={idx !== 0}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
            </Zoom>
            {/* {!sliderMode && image.altText ? <legend>{image.altText}</legend> : null} */}
          </div>
        ))}
      </ImagesContainer>
    </Wrapper>
  );
};

export default Gallery;
