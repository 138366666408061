import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  position: relative;
  padding: 0px 0px;
  margin: 55px 0;
  /* background-color: #fafafa; */
  /* margin: 30px 0; */
  /* 
  &:before {
    content: "";
    position: relative;
    top: 0px;
    left: 40%;
    right: 40%;
    height: 2px;
    background: #000;
    z-index: 100;
  } */
  h2 {
    font-weight: 700;
    margin-bottom: 25px;

    @media ${device.laptopM} {
      font-size: 25px;
    }
  }

  &:last-child {
    margin-bottom: 75px;
  }

  &:first-child {
    margin-top: 75px;
  }

  @media ${device.tablet} {
    /* padding: 50px 0px; */
  }

  @media ${device.laptop} {
    /* padding: 50px 0px; */
    &:last-child {
      margin-bottom: 75px;
    }

    &:first-child {
      margin-top: 75px;
    }
  }
`;

export const Subhead = styled.h2`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  grid-gap: 0px;

  @media ${device.tablet} {
    grid-gap: 10px;
  }

  .media,
  .text {
    flex: 1;
  }

  .text {
    padding: 30px 20px;
    background-color: #f2f3f5;
  }

  .media {
    @media ${device.tablet} {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .gatsby-image-wrapper {
    background-color: #fafafa;
  }

  .content {
    /* flex: 1; */
    p,
    a {
      font-size: 14px;

      @media ${device.laptopM} {
        font-size: 15px;
      }
    }
  }

  .video {
    position: relative;
    padding-bottom: 125%;
    width: 100%;

    @media ${device.tablet} {
      padding-bottom: 56%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;

    .media {
      margin-bottom: 0;

      &.is-video {
        background-color: #000;
        display: flex;
        justify-self: center;
      }
    }

    .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 30px 40px;
    }

    &.reverse {
      flex-direction: row-reverse;

      .media {
      }

      .text {
      }
    }
  }

  @media ${device.laptopL} {
    .text {
      padding: 50px 80px;
    }
  }
`;
