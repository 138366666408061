import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import Review from './Review';
import RatingStars from '../RatingStars';
import Pagination from './Pagination';

import { Wrapper, Summary, Grid } from './Stamped.styles';
import { useBreakpoint } from '../../../../utils';
import { useLocale } from '../../../../services/i18n/useLocale';

const API_KEY = 'pubkey-41zB3i56kAeBKD1JN4FM3aefj1mBNl';
const STORE_URL = 'checkout.ipone.com';

const Stamped = ({ productId, locale }) => {
  const device = useBreakpoint();
  const intl = useLocale();
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);

  let perPage = 6;

  if (device === 'mobile') {
    perPage = 2;
  } else if (device === 'tablet') {
    perPage = 4;
  }

  useEffect(() => {
    async function fetchData() {
      const params = {
        type: 'full-page',
        'productIds[]': productId,
        minRating: 1,
        apiKey: API_KEY,
        storeUrl: STORE_URL,
        page: page,
        skip: perPage,
        widgetLanguage: locale,
        isSyndication: true,
        elementId: 1,
      };
      const req = await fetch(
        `https://stamped.io/api/widget/reviews?${Object.keys(params)
          .map((key) => key + '=' + params[key])
          .join('&')}`
      );

      try {
        const data = await req.json();
        if (!data.data?.length) {
          throw new Error('no data');
        }
        setData(data);
      } catch (e) {}
    }
    fetchData();
  }, [page, perPage, productId, locale]);

  if (data === null) {
    return <Wrapper></Wrapper>;
  }

  const totalRating = Math.ceil(data.rating * 10) / 10;
  const totalPage = Math.ceil(data.total / perPage);

  return (
    <Wrapper>
      <Summary>
        <div className="totalRating">{Math.round(totalRating)}/5</div>
        <div className="stars-avis">
          <RatingStars rate={data.rating} starWidth={20} />
          <div>
            ({data.total} {intl.formatMessage('ecom.reviews-count')})
          </div>
        </div>
      </Summary>
      <Grid>
        {data?.data?.map((item) => (
          <Review
            key={item.id}
            title={item.reviewTitle}
            rating={item.reviewRating}
            comment={item.reviewMessage}
            date={item.reviewDate}
            username={item.author}
            verifiedText={intl.formatMessage('ecom.reviews-buyer')}
          />
        ))}
      </Grid>
      <Pagination
        maxPage={totalPage}
        page={data.page}
        onClickAction={setPage}
      />
    </Wrapper>
  );
};

export default Stamped;
