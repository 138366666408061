import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useSwipeable } from 'react-swipeable';

// import { Icon } from '../icons';
// import NextIcon from '../../icons/Next.svg';
// import { isClient } from '../../utils';

import {
  Wrapper,
} from './Slider.styles';

const Slider = ({
  children,
  activeIndex = 0,
  hideDots = false
}) => {
  const [slideIndex, setSlideIndex] = useState(activeIndex);

  useEffect(() => {
    setSlideIndex(activeIndex);
  }, [activeIndex]);


  const slidesCount = children.length;

  const nextSlide = useCallback(() => {
    setSlideIndex(c => c >= slidesCount - 1 ? 0 : c + 1);
  }, [slidesCount]);

  const prevSlide = useCallback(() => {
    setSlideIndex(c => c <= 0 ? slidesCount - 1 : c - 1);
  }, [slidesCount]);

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide
  });
  const isSlider = children.length > 1;

  return (
    <Wrapper>
      <div {...handlers}>
        {children.map((e, idx) => (
          <div
            className={cn('slide', {
              first: idx === 0,
              show: slideIndex === idx
            })}

            key={e.key || idx}
          >
            {e}
          </div>
        ))}
      </div>
      {isSlider && !hideDots && (
        <div className="dots">
          {children.map((e, idx) => (
            <button
              className={cn('dot clear', {
                show: slideIndex === idx
              })}
              key={e.key || idx}
              onClick={() => setSlideIndex(idx)}
              aria-label={`Slide ${idx + 1}`}
            >
              <div className="circle" />
            </button>
          ))}
        </div>
      )}
      {/* {isSlider && isClient ? (
        <div className="pager">
          <button
            onClick={prevSlide}
            className="clear prev"
            aria-label="Previous"
          >
            <Icon src={NextIcon} />
          </button>
          <button
            onClick={nextSlide}
            className="clear"
            aria-label="Next"
          >
            <Icon src={NextIcon} />
          </button>
        </div>
      ) : null} */}
    </Wrapper>
  );
};

export default Slider;
