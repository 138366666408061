import React, { useRef } from 'react';
import { useBreakpoint, vimeoParser, youtubeParser } from '.';
import useIntersection from './useIntersection';

const LazyVideoFrame = ({ src, srcMobile, className }) => {
  const ref = useRef();
  const device = useBreakpoint();
  const intersection = useIntersection(
    ref,
    {
      root: null,
      rootMargin: `0px 0px 0px 0px`,
      threshold: 0,
    },
    true
  );
  let youtubeId = src ? youtubeParser(src) : undefined;
  let vimeoId = src ? vimeoParser(src) : undefined;

  if (srcMobile && device === 'mobile') {
    youtubeId = youtubeParser(srcMobile);
    vimeoId = vimeoParser(srcMobile);
  }

  const display = intersection?.isIntersecting;

  return (
    <div ref={ref} className={className}>
      {!!(display && youtubeId) && (
        <iframe
          title="Youtube video"
          src={`https://www.youtube-nocookie.com/embed/${youtubeId}?controls=0&modestbranding=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="lazy"
        />
      )}
      {!!(display && vimeoId) && (
        <iframe
          title="Youtube video"
          src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="lazy"
        />
      )}
    </div>
  );
};

export default LazyVideoFrame;
