import React from 'react';
import Image from 'gatsby-image';
import cn from 'classnames';

import HtmlRender from '../../../components/common/Html';

import { Wrapper, Container, Subhead } from './ContentBlock.styles';

import { Title } from './common.styles';
import LazyVideoFrame from '../../../utils/LazyVideoFrame';
import classNames from 'classnames';

const ContentBlock = ({
  subhead,
  title,
  image,
  isTextLeft = false,
  video,
  videoMobile,
  text,
}) => {
  if (!title && !text && (!image || !video)) {
    return null;
  }

  return (
    <>
      <Wrapper>
        {!!subhead && <Subhead>{subhead}</Subhead>}
        <Container
          className={cn({
            reverse: isTextLeft,
          })}
        >
          <div className={classNames("media", {
            "is-video": !!video
          })}>
            {!video && image && (
              <Image
                fluid={{
                  ...image,
                  sizes: `(max-width: 660px) 85vw, 660px`,
                }}
              />
            )}
            {video && (
              <LazyVideoFrame
                src={video}
                srcMobile={videoMobile}
                className="video"
              />
            )}
          </div>
          <div className="text">
            {!!title && <Title>{title}</Title>}
            <HtmlRender html={text} className="content" />
          </div>
        </Container>
      </Wrapper>
    </>
  );
};

export default ContentBlock;
