import styled from 'styled-components';

export const Wrapper = styled.div`

  --color-black : black;
  --color-grey : #7E7E7E;

  max-width: 320px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-size : 14px
  line-height : 22px;
  font-weight : normal;
  padding-top : 12px;
  color : var(--color-black);
  border-top : 1px solid var(--color-black);

  .avis-date-wrapper {
      display: flex;
      justify-content : space-between;
      align-items : center;
      column-gap = 10px;
      flex-wrap: wrap;
  }

  .avis{
      font-weight : bold;
  }
  .avis:first-letter, .username:first-letter{
      text-transform: uppercase
    }

  .username, .certified{
    color : var(--color-grey);
  }

  .certified{
    display : flex;
    align-items : center;
    column-gap : 4px;
    padding-left : 2px;
    font-size : 12px
    line-height : 22px; 
  }

  .arrow-green{
    width : 8px;
    height : 5px;
  }

`;
