import styled from 'styled-components';
// import { device } from '../../utils';

export const Wrapper = styled.section`
  position: relative;
  .slide {
    position: relative;
    opacity: 0;
    transition: opacity 600ms;
    will-change: opacity;

    &:not(.first) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.show {
      opacity: 1;
      z-index: 100;
    }
  }

  .dots {
    position: absolute;
    bottom: -30px;
    margin-left: 50%;
    transform: translateX(-50%);

    .dot {
      display: inline-block;
      opacity: .5;
      transition: opacity 600ms;
      will-change: opacity;
      width: 30px;
      height: 40px;
      text-align: center;

      .circle {
        display: block;
        width: 8px;
        height: 8px;
        background: #000;
        border-radius: 50%;
        margin: 0 auto;
      }

      &.show {
        opacity: .8;
      }
    }
  }

  /* .pager {
    position: absolute;
    bottom: 20px;
    left: 20px;

    button {
      border: 1px solid #FFF;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 38px;
      height: 38px;
      background-color: rgba(0, 0, 0, 0.4);
      transition: background-color 300ms;
      padding: 0;

      &.prev {
        svg {
          transform: rotate(-180deg);
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    svg {
      width: 12px;
      height: 15px;
      fill: #FFF!important;
    }
  } */
`;