import React from 'react';

import { Wrapper, Legend } from './Rating.styles';
import RatingStars from './RatingStars';

import trads from '../trads';
import { getLocaleSettings } from '../../../utils';
import { useLocale } from '../../../services/i18n/useLocale';

const Rating = ({ rate = 0 }) => {
  const intl = useLocale();
  const settings = getLocaleSettings(intl.locale);

  if (!settings.shop) {
    return null;
  }

  return (
    <Wrapper href="#reviews">
      <RatingStars rate={rate} />
      <Legend>{intl.formatMessage(trads.readReviews)}</Legend>
    </Wrapper>
  );
};

export default Rating;
